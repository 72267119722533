<template>
  <div class="auth">
    <div class="auth_box">
      <a href="/"
        ><img class="logo" src="@/assets/img/logo_600.png" alt="WKT"
      /></a>
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style scoped>
.auth {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
  flex-direction: column;

  padding: 2rem 0;

  overflow-y: auto;

  background-color: #000;
  background-size: auto 100%;
  background-image: url("../../assets/img/img_login_background.jpg");
  background-repeat: repeat;
  background-attachment: fixed;
  animation: 120s linear 0s infinite normal none running auth_animation;
}
@keyframes auth_animation {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: calc(-100vw + 100%) 0px;
  }
}
.auth::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
}

.auth .auth_box {
  position: relative;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgb(238, 238, 238);

  width: 460px;
  max-width: 95%;
  padding: 24px;
  z-index: 1;
}
.auth img.logo {
  display: block;
  margin: 0 auto 32px auto;
  width: 100px;
  max-width: 90%;
}

.auth .auth_box :deep(h1) {
  margin: 0 0 24px 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.auth .auth_box :deep(.section) {
  margin-bottom: 16px;
}
.auth .auth_box :deep(.section a) {
  font-size: 0.852rem;
  color: #333;
  text-decoration: none;
}
.auth .auth_box :deep(.section a:hover) {
  text-decoration: underline;
}

.auth .auth_box :deep(.section > label) {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.25rem;
}
.auth .auth_box :deep(.section > input) {
  display: block;
  width: 100%;
  margin: 8px 0 0 0;
}
.auth .auth_box :deep(.section > button) {
  display: block;
  width: 100%;
}
.auth .auth_box :deep(.section .error) {
  margin: 2px 0;
  padding: 0;
  font-size: 0.852rem;
  color: rgb(190, 13, 13);
}
</style>
